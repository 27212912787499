import { buildSchema,buildProperty, buildCollection,ExtraActionsParams } from "@camberi/firecms";





export default class Bridges {

    constructor() {

    }

    static BuildCollection (isadmin:boolean, issuperadmin:boolean, groupname:string) {
      return buildCollection({
        path: "bridges",
        description: "collection of bridge rewards to be dropped from packs",
        group:groupname,
        exportable:false,
        schema: Bridges.schema(),
        name: "Bridges",
        permissions: ({entity,path,user,authController,context}) => { 
            return ({
                read: isadmin,
                create: isadmin,
                update: isadmin,
                delete: isadmin
            });
        },
      })
    }


  

    static schema () {
        return buildSchema({
            name: "Bridges",
            properties: {
              
              name: {
                title: "Name",
                dataType: "string"
              },
              nftID: {
                title: "NFT ID (only numbers)",
                dataType: "string"
              },
              type: {
                title: "type",
               
                  dataType:"string",
                  config: {
                    enumValues: {
                        default: "Default",
                        community1: "community1",
                        community2: "community2"
                    }
                  },  
              }, 
              image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
                title: "image",
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "bridges",
                        acceptedFiles: ["image/*"]
                    }
                }
              }),
              chancesToDrop: {
                title: "chances to drop (%)",
                description: "chances for this bridge to drop from the booster pack",
                dataType: "number"
              },
              
            
                
            }
          });
    }


    
}